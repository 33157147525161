<template>
  <div class="page-container">
    <div>
      <div class="section-title">
        <AnalyticsTitle
          :title="$t('new-dashboard.header.title.analytics-workflow-session')"
          :tooltip-content="
            $t('new-dashboard.header.title.analytics-workflow-session')
          "
        ></AnalyticsTitle>
        <RemoteSearch
          class="analytics-workflow-session-table-search"
          :placeholder="
            $t('new-dashboard.header.search-placeholder-workflow-session')
          "
          :value="searchQuery"
          @change-additional="handleSearch"
        />
      </div>
      <div class="page-section table-container">
        <LazyCustomTable
          v-loading="isLoading"
          :data="sessionsData"
          :headers="tableHeaders"
          :options="tableOptions"
          :init-data-options="dataOptions"
          :data-count="sessionsTotalCount"
          :pagination-total="sessionsTotalCount"
          :rows-count="[pageSize]"
          :max-height="600"
          @update-data-options="handleDataOptionsUpdate"
        >
          <template v-slot:options="{ row }">
            <font-awesome-icon
              :icon="['fad', 'download']"
              class="cursor"
              @click="() => handleDownload(row)"
            ></font-awesome-icon>
          </template>
        </LazyCustomTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import AnalyticsTitle from '../AnalyticsTitle';
import LazyCustomTable from '@/components/Tables/LazyCustomTable';
import RemoteSearch from '@/components/Filters/Filter/RemoteSearch';

export default {
  name: 'analytics-workflow-session',
  components: {
    AnalyticsTitle,
    LazyCustomTable,
    RemoteSearch,
  },
  data() {
    return {
      isLoading: false,
      rawData: [],
      searchQuery: '',
      pageSize: 25,
      companyFacets: [
        { name: 'ID commande', value: 'scopusId' },
        { name: 'ID client', value: 'clientId' },
        { name: 'ID dossier', value: 'customerCaseId' },
      ],
      dataOptions: {
        currentPage: 1,
        totalPageCount: this.pageSize,
        filters: [],
        sort: {},
        search: '',
      },
      tableOptions: {
        search: false,
        showItemCount: true,
        showRowsCount: false,
        bulkActions: false,
        pagination: true,
        expandableRows: false,
      },
      debounceGetSessions: debounce(this.getSessions, 500),
    };
  },
  computed: {
    tableHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        prop: 'createdAt',
        path: 'createdAt',
        key: 'createdAt',
        label: 'Date',
      });

      tableHeaders.push({
        prop: 'sessionId',
        path: 'sessionId',
        key: 'sessionId',
        label: 'Session ID',
      });

      this.companyFacets.forEach((facet) => {
        tableHeaders.push({
          prop: facet.value,
          path: facet.value,
          key: facet.value,
          label: facet.name,
        });
      });

      tableHeaders.push({
        prop: 'options',
        label: this.$t('new-dashboard.table.header.analytics-usages.options'),
        template: true,
      });

      return tableHeaders;
    },
    sessionsData() {
      if (!this.rawData || !this.rawData.results) return [];
      return this.rawData.results.map((result) => {
        const item = {};
        item.sessionId = result.sessionId;
        item.createdAt = result.createdAt;
        this.companyFacets.forEach((facet) => {
          const facetValue = result.facets.find((el) =>
            el.startsWith(facet.value),
          );
          item[facet.value] = facetValue ? facetValue.split(':')[1] : 'N/A';
        });
        return item;
      });
    },
    sessionsTotalCount() {
      if (!this.rawData || !this.rawData.count) return 0;
      return this.rawData.count;
    },
  },
  async created() {
    await this.getSessions('', 1);
  },
  methods: {
    async getSessions(query, page) {
      this.isLoading = true;

      let searchQuery = null;
      if (query && query.trim().length) searchQuery = query;
      try {
        this.rawData = await this.filterContextSessions({
          searchQuery,
          pageSize: this.pageSize,
          page,
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    handleSearch(query) {
      console.log('handleDataOptionsUpdate');
      console.log(query);
      this.debounceGetSessions(query.value, 1);
    },
    async handleDataOptionsUpdate(options) {
      console.log('handleDataOptionsUpdate');
      const { currentPage } = options;
      if (currentPage === this.dataOptions.currentPage) return;
      await this.getSessions(this.searchQuery, currentPage);
      console.log(options);
    },
    setDataOptionsCurrentPage(currentPage) {
      this.dataOptions = Object.assign({}, this.dataOptions, {
        currentPage,
      });
    },
    async handleDownload(row) {
      try {
        this.isLoading = true;
        await this.getContextSessionLogsExport({ sessionId: row.sessionId });
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    ...mapActions('knowledgeModule', ['filterContextSessions']),
    ...mapActions('dashboard', ['getContextSessionLogsExport']),
  },
  watch: {
    rawData() {
      console.log('watch/rawData');
      const { page } = this.rawData;
      this.setDataOptionsCurrentPage(page);
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
}

.analytics-workflow-session-table-search {
  margin-left: auto;
  width: 320px;
  height: 40px;

  :deep() .el-input__prefix {
    padding-left: 5px;
    .search-icon {
      width: 20px;
      height: 20px;
    }
  }
}
.table-container {
  max-height: 80vh;
  overflow-y: scroll;
}

.analytics-download-header {
  background-color: white;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  overflow-x: auto;
  max-width: 100%;
}

.routers {
  .router-wrapper {
    width: 77px;
    height: 34px;
  }
  .router-link-active {
    background-color: rgba($blue-mayday, 0.1);
    border-radius: 4px;
    color: $blue-mayday !important;
    padding: 5px;
  }
  .router-link {
    width: 100%;
    height: 100%;
    color: $grey-4-mayday;
  }
}

.action-button-wrapper {
  background: none;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
  width: 16px;
  height: 21px;
}

.action-button {
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}
.cell-class {
  background-color: #f8f9fe;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-cell-class {
  background-color: #f8f9fe;
  border-top: none;
}

.cell .wrapper {
  display: block;
}
.page-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.page-section {
  margin-top: 20px;
}

.download-card-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.card-container {
}
</style>
