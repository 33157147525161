<template>
  <div class="users-administrate">
    <div
      class="d-flex flex-row justify-content-between users-administrate-header sticky-top"
    >
      <div class="d-flex flex-row quit-wrapper">
        <div class="d-flex align-items-center">
          <router-link to="/settings/users">
            <div
              class="d-flex justify-content-center align-items-center quit-redirection"
            >
              <div
                class="quit-icon-wrapper d-flex justify-content-center align-items-center mr-2"
              >
                <font-awesome-icon
                  class="quit-icon"
                  :icon="['fal', 'arrow-left']"
                ></font-awesome-icon>
              </div>
              {{
                $t('settings.users-administration.quit-administration-center')
              }}
            </div>
          </router-link>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center">
        <div v-if="selectedUsers.length" class="select-wrapper mr-2">
          <el-select
            v-model="bulkEdit"
            popper-class="select-bulk-edit"
            :placeholder="
              $tc(
                'settings.users-administration.bulk-edit.placeholder',
                selectedUsers.length,
                { count: selectedUsers.length },
              )
            "
          >
            <el-option
              v-for="(label, idx) in ['role', 'label', 'group']"
              :key="idx"
              :label="$t(`settings.users-administration.bulk-edit.${label}`)"
              :value="label"
            ></el-option>
          </el-select>
        </div>
        <div v-if="pending && selectedUsers.length">
          <el-button
            class="mr-2 bulk-renew-button"
            @click="renewInvitations(selectedUsers)"
            type="secondary"
          >
            <font-awesome-icon icon="sync" class="mr-2" />
            {{
              $tc(
                'settings.permissions.bulk-pending-user-actions.renew-invitation',
                selectedUsers.length,
              )
            }}
          </el-button>
        </div>
        <div
          v-if="selectedUsers.length"
          class="selected-users f-flex align-items-center"
        >
          <el-popconfirm
            class="indicator-popconfirm"
            :confirm-button-text="
              $t('settings.users-administration.drawer.main.confirm')
            "
            confirm-button-type="plain"
            :cancel-button-text="
              $t('settings.users-administration.drawer.main.cancel')
            "
            icon="el-icon-info"
            icon-color="red"
            :title="
              $t(
                'settings.users-administration.drawer.main.confirm-title-multiple',
              )
            "
            @confirm="handleDeleteUser({ userIds: selectedUsers })"
          >
            <div slot="reference">
              <el-button type="danger" class="mr-2 bulk-delete-button">{{
                $tc(
                  this.pending
                    ? 'settings.users-administration.bulk-delete-invitations'
                    : 'settings.users-administration.bulk-delete',
                  selectedUsers.length,
                  {
                    count: selectedUsers.length,
                  },
                )
              }}</el-button>
            </div>
          </el-popconfirm>
        </div>
        <el-input
          :placeholder="
            $t('settings.users-administration.filters.search-placeholder')
          "
          v-model="search"
          class="mr-2 search"
          clearable
          @input="launchHandleSearchDebounce"
          @clear="handleSearch"
        >
          <i slot="prefix" class="el-input__icon" v-if="!search"
            ><font-awesome-icon
              :icon="['fal', 'search']"
              class="search-icon"
            ></font-awesome-icon
          ></i>
        </el-input>
        <filters-popover
          :filters="prepareFilters"
          :selectedFilters="usersForAdministrationTableFilters"
          @update-filter="updateFilter"
          @empty-filters-search="emptyFiltersSearch"
        />
      </div>
    </div>
    <users-administration-table
      class="component-table"
      :columns="columns"
      :tableData="formattedEmployees"
      :currentPage="usersForAdministrationTableCurrentPage"
      :pageSize="usersForAdministrationTableTotalPageCount"
      :totalCount="usersForAdministrationCount"
      :loading="usersForAdministrationTableIsLoading"
      :selected-users="selectedUsers"
      :isFilteredAndEmpty="isFilteredAndEmpty"
      :exportLoading="exportUserLoading"
      :pending="pending"
      @expand-user="userDrawer = $event"
      @delete-user="handleDeleteUser($event)"
      @handle-change-page="handleChangePage($event)"
      @change-page-size="handleChangePageSize($event)"
      @handle-select-user="handleSelectUser"
      @select-all="handleSelectAll"
      @empty-filters-search="emptyFiltersSearch"
      @export-users="exportUsers({ isPending: pending })"
      @renew-invite="renewInvitations($event)"
      @copy-link="copyInvitation($event)"
    ></users-administration-table>
    <user-drawer
      v-if="userDrawer"
      :show="userDrawer.length > 0"
      :user="focusEmployee"
      :loading="usersForAdministrationTableIsLoading"
      :pending="pending"
      @update-focused-user="handleUpdateFocusedUser"
      @close="userDrawer = ''"
      @delete-user="handleDeleteUser($event)"
      @refresh-helpdesk-id="
        syncEmployeeHelpdeskId({ userId: $event, helpdesk: 'zendesk' })
      "
    ></user-drawer>
    <div>
      <update-user-group-modal
        v-if="modals.group"
        :groups="groups"
        :display="modals.group"
        :multipleUpdate="selectedUsers.length"
        @update-user-group="handleBulkUpdateUser({ ids: $event, key: 'group' })"
        @close="handleCloseModal"
      ></update-user-group-modal>
      <update-user-label-modal
        v-if="modals.label"
        :categories="userLabelCategories"
        :display="modals.label"
        :multipleUpdate="selectedUsers.length"
        @update-user-labels="
          handleBulkUpdateUser({ ids: $event, key: 'labels' })
        "
        @close="handleCloseModal"
      ></update-user-label-modal>
      <update-user-role-modal
        v-if="modals.role"
        :roles="roles"
        :display="modals.role"
        :multipleUpdate="selectedUsers.length"
        @update-user-role="handleBulkUpdateUser({ ids: $event, key: 'role' })"
        @close="handleCloseModal"
      ></update-user-role-modal>
    </div>
  </div>
</template>

<script>
import UsersAdministrationTable from './UsersAdministrationTable.vue';
import FiltersPopover from './FiltersPopover.vue';
import UpdateUserRoleModal from '@/components/Modals/UpdateUserRoleModal.vue';
import UpdateUserGroupModal from '@/components/Modals/UpdateUserGroupModal.vue';
import UpdateUserLabelModal from '@/components/Modals/UpdateUserLabelModal.vue';
import UserDrawer from './UserDrawer/UserDrawer.vue';
import { UserAdministrationColumns } from './UserAdministrationColumn';
import { mapGetters, mapActions, mapState } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  name: 'users-administrations',
  components: {
    UsersAdministrationTable,
    FiltersPopover,
    UserDrawer,
    UpdateUserRoleModal,
    UpdateUserGroupModal,
    UpdateUserLabelModal,
  },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await this.getUsersForAdministrationFiltered();
    if (this.$route.query.invitation) {
      this.addToUsersAdministrationFilter({
        key: 'invitationToken',
        path: 'invitationToken',
        type: 'Array',
        values: ['expired'],
      });
    }
  },

  data() {
    return {
      search: '',
      userDrawer: '',
      selectedUsers: [],
      bulkEdit: '',
      modals: {
        group: false,
        label: false,
        role: false,
      },
      launchHandleSearchDebounce: debounce(() => this.handleSearch(), 200),
    };
  },
  computed: {
    columns() {
      let filteredColumns = UserAdministrationColumns;
      if (!this.hasZendesk) {
        filteredColumns = filteredColumns.filter(
          (col) => col.key !== 'helpdeskId',
        );
      }
      if (this.pending) {
        filteredColumns = filteredColumns.filter(
          (col) => col.key !== 'externalId',
        );
      } else {
        filteredColumns = filteredColumns.filter(
          (col) => col.key !== 'invitation',
        );
      }
      return filteredColumns;
    },
    formattedEmployees() {
      return this.usersForAdministration.map((employee) => {
        const { token, expiration, isExpired } =
          this.parseInvitationRelatedData(employee);
        return {
          username:
            employee.username && employee.username !== ' '
              ? employee.username
              : employee.email,
          createdAt: employee.createdAt,
          email: employee.email,
          roles: employee.role.reduce((acc, val) => {
            acc.push(val.name);
            return acc;
          }, []),
          group: employee.groups[0] ? employee.groups[0].name : '',
          labels: employee.labels || [],
          id: employee.id,
          helpdeskId: employee.helpdeskId,
          externalId: employee.externalId,
          invitationToken: token,
          expirationDate: expiration,
          isExpired: isExpired,
        };
      });
    },
    prepareFilters() {
      let filters = {
        role: {
          key: 'role',
          label: this.$t('settings-layout.menu-data.roles'),
          options: this.roles.map((role) => ({
            label: role.name,
            value: role.id,
          })),
          searchable: true,
          type: 'Array',
          values: [],
          icon: ['fal', 'medal'],
          input: 'checkbox',
        },
        groups: {
          key: 'groups',
          label: this.$t('filters.groups.facets.group'),
          options: this.groups.map((group) => ({
            label: group.name,
            value: group.id,
          })),
          type: 'Array',
          values: [],
          icon: ['fal', 'users'],
          input: 'checkbox',
          searchable: true,
        },
        labels: {
          key: 'labels',
          label: this.$t('filters.groups.facets.label'),
          options: this.userLabelCategories.map((category) => ({
            label: category.name,
            color: category.color,
            icon: category.icon,
            options: category.companyUserLabels.map((label) => {
              return {
                label: label.name,
                value: label.id,
              };
            }),
          })),
          type: 'Array',
          searchable: true,
          grouped: true,
          icon: ['fal', 'tags'],
          values: [],
          input: 'checkbox',
        },
      };

      if (this.pending) {
        filters = {
          ...filters,
          invitationToken: {
            key: 'invitationToken',
            label: this.$t('settings-layout.menu-data.invitation'),
            options: [
              {
                label: this.$t(`settings.permissions.pending-table.expired`),
                value: 'expired',
              },
              {
                label: this.$t(`settings.permissions.pending-table.pending`),
                value: 'pending',
              },
            ],

            searchable: false,
            type: 'Array',
            values: [],
            icon: ['fal', 'envelope'],
            input: 'checkbox',
          },
        };
      }
      return filters;
    },

    focusEmployee() {
      return this.usersForAdministration.filter(
        (employee) => employee.id === this.userDrawer,
      )[0];
    },
    isFilteredAndEmpty() {
      return (
        (this.usersForAdministrationTableFilters.length > 0 ||
          this.usersForAdministrationTableSearch !== '') &&
        this.usersForAdministration.length === 0
      );
    },
    ...mapState(['userId']),
    ...mapGetters(['hasZendesk']),
    ...mapGetters('adminModule', [
      'usersForAdministration',
      'usersForAdministrationTableFilters',
      'usersForAdministrationTableSearch',
      'usersForAdministrationTableCurrentPage',
      'usersForAdministrationTableTotalPageCount',
      'usersForAdministrationCount',
      'usersForAdministrationTableIsLoading',
      'groups',
      'roles',
      'userLabelCategories',
      'exportUserLoading',
    ]),
  },
  beforeDestroy() {
    this.emptyFiltersSearch();
  },
  methods: {
    parseInvitationRelatedData(employee) {
      const res = { token: 'expired', expiration: '', isExpired: '' };

      // 1. Eval exit conditions
      if (!this.pending) return res;
      if (!employee.invitationToken || !employee.invitationToken.length) {
        return res;
      }

      const { isExpired, expirationDate } = employee.invitationToken[0];

      // 2. Parse token
      res.token = isExpired ? 'expired' : 'pending';

      // 3. Parse expirationDate
      if (expirationDate)
        res.expiration = new Date(
          parseInt(expirationDate),
        ).toLocaleDateString();

      // 4. Parse isExpired
      if (isExpired !== undefined) res.isExpired = isExpired;

      return res;
    },

    updateFilter($event) {
      this.addToUsersAdministrationFilter($event);
    },

    handleSearch() {
      this.handleUsersSearch(this.search);
    },
    handleUpdateFocusedUser($event) {
      this.updateUser($event);
    },
    handleSelectUser($event) {
      const { operation, userId } = $event;
      if (operation) this.selectedUsers.push(userId);
      else {
        const userIdx = this.selectedUsers.indexOf(userId);
        if (userIdx > -1) this.selectedUsers.splice(userIdx, 1);
      }
    },
    syncEmployeeHelpdeskId({ userId, helpdesk }) {
      let loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${this.$t(
          'settings.users-administration.drawer.main.sync-zendesk-loading',
        )}`,
      });
      this.syncEmployeeWithHelpdesk({ userId, helpdesk })
        .then(() => {
          loadingMessage.close();
          loadingMessage = this.$message({
            duration: 3000,
            type: 'success',
            message: this.$t(
              'settings.users-administration.drawer.main.sync-zendesk-success',
            ),
          });
        })
        .catch(() => {
          loadingMessage = this.$message({
            duration: 3000,
            type: 'warning',
            message: this.$t(
              'settings.users-administration.drawer.main.sync-zendesk-failure',
            ),
          });
        });
    },
    handleDeleteUser({ userIds }) {
      if (!userIds.includes(String(this.userId))) {
        this.selectedUsers = [];
        return this.deleteUser({ userIds });
      }
      this.$message({
        type: 'error',
        message: this.$t('settings.users-administration.no-delete-own'),
      });
    },
    handleSelectAll($event) {
      if ($event)
        this.selectedUsers = this.usersForAdministration.map((user) => user.id);
      else this.selectedUsers = [];
    },
    handleBulkUpdateUser($event) {
      if ($event.key === 'labels')
        this.updateManyEmployeesLabels({
          userIds: this.selectedUsers,
          labelIds: $event.ids,
          pending: this.pending,
        });
      if ($event.key === 'group')
        this.updateManyEmployeesGroup({
          userIds: this.selectedUsers,
          groupIds: $event.ids,
          pending: this.pending,
        });
      if ($event.key === 'role')
        this.updateManyEmployeesRole({
          userIds: this.selectedUsers,
          roleId: $event.ids,
          pending: this.pending,
        });
      this.selectedUsers = [];
      this.handleCloseModal();
    },
    async renewInvitations(invitedUsers) {
      this.renewUsersInvitations({ invitedUsers }).then(() => {
        this.$message({
          duration: 3000,
          type: 'success',
          message: this.$tc(
            'settings.permissions.pending-table.renew-succeed',
            invitedUsers.length,
          ),
        });
      });
    },
    async copyInvitation(pendingUserId) {
      const user = await this.usersForAdministration.find(
        (f) => f.id === pendingUserId,
      );
      const link = user.invitationToken[0].invitationLink;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.$message({
            message: this.$t('settings.permissions.pending-table.copy-succeed'),
            type: 'success',
          });
        })
        .catch(() => {
          this.$message({
            message: this.$t('settings.permissions.pending-table.copy-error'),
            type: 'error',
          });
        });
    },

    handleCloseModal() {
      this.bulkEdit = '';

      Object.keys(this.modals).map((el) => (this.modals[el] = false));
    },
    ...mapActions('adminModule', [
      'addToUsersAdministrationFilter',
      'getUsersForAdministrationFiltered',
      'handleUsersSearch',
      'updateUser',
      'deleteUser',
      'handleChangePage',
      'handleChangePageSize',
      'updateManyEmployeesGroup',
      'updateManyEmployeesRole',
      'updateManyEmployeesLabels',
      'emptyFiltersSearch',
      'exportUsers',
      'syncEmployeeWithHelpdesk',
      'renewUsersInvitations',
    ]),
  },
  watch: {
    bulkEdit() {
      this.modals[this.bulkEdit] = true;
    },
    usersForAdministrationTableSearch: {
      handler(val) {
        this.search = val;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.users-administrate-header {
  background-color: #f9f9fe;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  overflow-x: auto;
  max-width: 100%;
  z-index: 2 !important;
}

.search-icon:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.select-wrapper {
  height: 34px;
  :deep() .el-select .el-input .el-input__inner {
    height: 34px !important;
    line-height: 34px;
    width: fit-content;
    background-color: $blue-mayday;
    border: none;
    &::placeholder {
      color: white !important;
    }
  }

  :deep() .el-select .el-input .el-select__caret {
    color: white;
  }
}
.search {
  height: 34px;
  max-width: 300px;
  min-width: 150px;
  width: 100%;
  :deep() .el-input__inner {
    height: 100%;
  }
}

:deep() .el-input__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trash {
  &:hover {
    color: $red-mayday;
    cursor: pointer;
  }
}

.quit-redirection {
  color: $grey-7-mayday;

  &:hover {
    color: $blue-mayday;

    border-radius: 4px;
  }
}

.quit-wrapper {
  padding: 5px;
  &:hover {
    background-color: $grey-2-mayday;
    border-radius: 4px;
  }
}

.bulk-delete-button {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-bulk-edit {
  color: white;
}

.bulk-renew-button {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
