<template>
  <div
    class="row hosting-content-row"
    v-loading="collectionsViewerIsLoading || localLoading"
    :element-loading-text="importArticleLoader"
    :key="`${focusContentId}-${editingLanguage}`"
    ref="parametricContentBuilder"
  >
    <div v-if="displayUpdateTranslationsBanner" class="translation-banner">
      <UpdateTranslationsBanner
        @update-translations="openUpdateTranslationsModal"
        @close-banner="handleHideUpdateTranslationsBanner"
      ></UpdateTranslationsBanner>
    </div>
    <div class="container">
      <div
        class="position-relative"
        :class="displayOnWebParametric"
        v-if="rootId"
      >
        <div
          v-if="(!languageIsSet || forceBlur) && focusKnowledgeIsMultilinguale"
          class="row justify-content-center untranslate-content"
        >
          <div class="col-lg-9 col-md-10 col-sm-12">
            <div class="content-blur"></div>
          </div>
        </div>
        <KnowledgeEditorMainTitle
          class="row"
          :content="root"
          :contributors="contributors"
          :total="total"
          :step-id="stepId"
          :backlinks="focusContentBacklinks"
          :editable="editable"
          :loading-languages="loadingLanguages"
          :translation-dropdown-loading="translationDropdownLoading"
          :display-update-translations="displayUpdateTranslations"
          :is-staged-content="isStagedContent"
          :has-staged-version="hasStagedVersion"
          @update-content="
            isStagedContent
              ? handleUpdateStagedContentLabel($event)
              : handleUpdateContent($event, 'root')
          "
          @publish-staged-version="handlePublishStagedVersion"
          @open-content-settings="$emit('open-content-settings', $event)"
          @set-content-trust="setContentTrustMiddleware"
          @upsert-verification-policy="handleUpsertVerificationPolicy"
          @delete-verification-policy="handleDeleteVerificationPolicy"
          @upsert-publication-policy="handleUpsertPublicationPolicy"
          @delete-publication-policy="handleDeletePublicationPolicy"
          @set-similar-content-id="similarContentId = $event"
          @open-set-language-modal="openSetLanguageModal"
          @unset-language="openUnsetLanguageModal($event)"
          @update-translations="openUpdateTranslationsModal"
          @create-staged-version="createStagedVersion"
        />
        <div class="row content-editor">
          <div class="col-12 content-col">
            <ArticleBuilder
              v-if="root.type === 'Article'"
              :editable="editable"
              :is-content-editable="isContentEditable"
              :content="root"
              :is-staged-content="isStagedContent"
              v-loading="importArticleIsLoading"
              @update-written-content="delayedUpdateContent"
              @open-feedback-modal="openFeedbackModal(root)"
              @update-content="handleUpdateContent"
              @add-notification="addNotification($event)"
              @brain-import="handleBrainImport"
              @brain-files-merge="handleBrainFilesMerge"
              @brain-generate-from-prompt="handleBrainGenerateFromPrompt"
            />
            <DiagnosticBuilder
              v-if="root.type === 'Diagnostic'"
              :editable="editable"
              :is-content-editable="isContentEditable"
              :root="root"
              :step="step"
              :new-automation-id="newAutomationId"
              :is-staged-content="isStagedContent"
              @focus-node="focusNode"
              @add-child="addChild"
              @delete-child="deleteChild(step.id, $event)"
              @update-children-order="updateChildrenOrder"
              @toggle-key-step="handleToggleKeyStep"
              @update-content="handleUpdateContent"
              @update-written-content="delayedUpdateContent"
              @relocate-child="relocateChildLocal"
              @open-feedback-modal="openFeedbackModal(step)"
              @add-notification="addNotification($event)"
              @create-automation="handleCreateAutomation"
              @update-automation="handleUpdateAutomation"
              @delete-automation="handleDeleteAutomation"
              @local-update-content="localUpdateContent($event, 'focus')"
              @brain-import="handleBrainImport"
            />
          </div>
        </div>
      </div>
    </div>
    <SimilarContentToast
      v-if="displaySimilarContentToast"
      class="toast-position"
      @go-to-content="goToSimilarContent"
    />
    <SetLanguageToast
      v-if="displaySetLanguageToast"
      class="toast-position"
      :language="editingLanguage"
      :entity-type="root.type"
      @open-set-language-modal="openSetLanguageModal"
    />
    <TranslationToast
      v-for="(targetLanguage, index) in translationStatusesToDisplay"
      :key="index"
      class="toast-position"
      :style="`top: calc(6px + (${index} * 125px))`"
      :status="translationStatuses[targetLanguage].status"
      :target-language="translationStatuses[targetLanguage].targetLanguage"
      :source-language="editingLanguage"
      :trigger-source="translationStatuses[targetLanguage].triggerSource"
      @quit-translation-toast="handleQuitTranslationToast"
      @translation-try-again="handleRetryTranslation"
    />
    <BrainImportFileTaskToast
      v-for="(targetLanguage, index) in brainFileImportStatusesToDisplay"
      :key="index"
      class="toast-position"
      :style="`top: calc(6px + (${index} * 125px))`"
      :status="translationStatuses[targetLanguage].status"
      @click:refresh="reloadPage()"
      @click:retry="
        handleBrainImport(translationStatuses[targetLanguage].payload)
      "
      @close="handleQuitTranslationToast({ targetLanguage })"
    />
    <BrainFilesMergeTaskToast
      v-if="brainTasks['files-merge']"
      class="toast-position"
      style="top: 6px"
      :status="brainTasks['files-merge'].status"
      :retryable="!!brainTasks['files-merge'].payload"
      @click:refresh="reloadPage()"
      @click:retry="handleBrainFilesMerge(brainTasks['files-merge'].payload)"
      @close="handleBrainTaskInterruption('files-merge')"
    />
    <BrainGenerateFromPromptTask
      v-if="brainTasks['generation']"
      class="toast-position"
      style="top: 6px"
      :status="brainTasks['generation'].status"
      :retryable="!!brainTasks['generation'].payload"
      @click:refresh="reloadPage()"
      @click:retry="handleBrainFilesMerge(brainTasks['generation'].payload)"
      @close="handleBrainTaskInterruption('generation')"
    />
    <SetLanguageModal
      v-if="displaySetLanguageModal"
      :display="displaySetLanguageModal"
      :target-language="language"
      :source-language="editingLanguage"
      :allow-automatic-translation="true"
      :available-source-languages="focusContent.setLanguages"
      @set-new-language="setNewLanguage"
      @close="
        setLanguageModal = false;
        language = '';
      "
    />
    <UpdateTranslationsModal
      v-if="updateTranslationsModal"
      :display="updateTranslationsModal"
      :source-language="editingLanguage"
      :available-source-languages="availableLanguagesForUpdateTranslations"
      @update-translations="handleUpdateTranslations"
      @close="updateTranslationsModal = false"
    />
    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="unsetLanguageModalUniqueKey"
      @choice="unsetLanguage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';

// COMPONENTS
import ArticleBuilder from './ParametricArticleBuilder';
import DiagnosticBuilder from './ParametricDiagnosticBuilder/ParametricDiagnosticBuilder';
import KnowledgeEditorMainTitle from './ParametricContentMainTitle/KnowledgeEditorMainTitle';
import SetLanguageToast from '@/components/Modals/SetLanguageToast';
import SetLanguageModal from '@/components/Modals/SetLanguageModal';
import UpdateTranslationsModal from '@/components/Modals/UpdateTranslationsModal';
import UpdateTranslationsBanner from '../../../components/Modals/UpdateTranslationsBanner.vue';
import SimilarContentToast from '@/components/Modals/SimilarContentToast';
import TranslationToast from '@/components/Modals/TranslationToast.vue';
import BrainImportFileTaskToast from '@/components/Modals/BrainTaskToasts/BrainImportFileTaskToast.vue';
import BrainFilesMergeTaskToast from '@/components/Modals/BrainTaskToasts/BrainFilesMergeTaskToast.vue';
import BrainGenerateFromPromptTask from '@/components/Modals/BrainTaskToasts/BrainGenerateFromPromptTask.vue';
import ModalEvents from '@/components/Modals/ModalEvents';

import KnowledgeMixin from 'mixins/KnowledgeMixin/KnowledgeMixin';

export default {
  name: 'parametric-content-builder',
  mixins: [KnowledgeMixin],
  components: {
    KnowledgeEditorMainTitle,
    ArticleBuilder,
    DiagnosticBuilder,
    SimilarContentToast,
    SetLanguageToast,
    SetLanguageModal,
    ModalEvents,
    TranslationToast,
    BrainImportFileTaskToast,
    BrainFilesMergeTaskToast,
    BrainGenerateFromPromptTask,
    UpdateTranslationsModal,
    UpdateTranslationsBanner,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      content: this.focusContent,
      contributors: [],
      total: 0,
      step: {},
      diagBuilderKey: 0,
      newAutomationId: null,
      debounceUpdateContent: debounce(function (variables) {
        this.handleUpdateContent(variables);
      }, 2000),
      debounceUpdateStagedContent: debounce(function (variables) {
        this.updateStagedContent(variables);
      }, 2000),
      setLanguageModal: false,
      language: '',
      similarContentId: '',
      languageToUnset: '',
      localLoading: false,
      targetLanguage: '',
      forceBlur: false,
      translationStatuses: {},
      translationStatusesToDisplay: [],
      brainFileImportStatusesToDisplay: [],
      translationDropdownLoading: false,
      loadingLanguages: [],
      displayUpdateTranslations: false,
      updateTranslationsModal: false,
      hideUpdateTranslationsBanner: true,
      brainTasks: {},
    };
  },
  computed: {
    isContentEditable() {
      return (
        this.editable &&
        !this.root.isReadOnly &&
        this.root.userPermissions &&
        Boolean(this.root.userPermissions.canUpdateContent)
      );
    },
    isParametric() {
      return this.$route.name.includes('web-parametric');
    },
    displayOnWebParametric() {
      return this.isParametric ? 'col-12' : 'container';
    },
    displaySimilarContentToast() {
      return (
        !this.isParametric &&
        !this.collectionsViewerIsLoading &&
        !!this.similarContentId
      );
    },
    importArticleLoader() {
      return this.importArticleIsLoading
        ? this.$t(
            'knowledge.article-builder.side-bar.imported-articles.loading',
          )
        : '';
    },
    rootId() {
      return this.root ? this.root.id : null;
    },
    languageIsSet() {
      return (
        // - setLanguages can be empty if migration failed. In that case we don't want display toast because kb is monolingual 'default'
        // - if editingLanguage is not in setLanguages so the content is not translate
        !this.root ||
        !this.root.setLanguages.length ||
        !!this.root.setLanguages.filter((lang) => lang === this.editingLanguage)
          .length
      );
    },
    displaySetLanguageToast() {
      return (
        this.focusKnowledgeIsMultilinguale &&
        !this.isParametric &&
        !this.collectionsViewerIsLoading &&
        !this.languageIsSet &&
        !this.language &&
        !this.focusContent.lockId &&
        !this.displayTranslationToasts
      );
    },
    displaySetLanguageModal() {
      return this.focusKnowledgeIsMultilinguale && this.setLanguageModal;
    },
    unsetLanguageModalUniqueKey() {
      return `unset-content-${this.rootId}`;
    },
    stepId() {
      return this.step ? this.step.id : null;
    },
    root: {
      get() {
        this.forceStepValue();
        return this.focusContent;
      },
      set(val) {
        this.$emit('change-route-type', val.type);
      },
    },
    displayTranslationToasts() {
      return (
        this.translationStatusesToDisplay &&
        this.translationStatusesToDisplay.length
      );
    },
    availableLanguagesForUpdateTranslations() {
      if (!this.focusContent) return [];
      return this.focusContent.setLanguages.filter(
        (lang) => lang !== this.editingLanguage,
      );
    },
    displayUpdateTranslationsBanner() {
      return (
        this.displayUpdateTranslations && !this.hideUpdateTranslationsBanner
      );
    },
    hasStagedVersion() {
      return !!this.focusContent && !!this.focusContent.stagedData;
    },
    isStagedContent() {
      const { staged } = this.hasStagedVersion && this.$route.query;
      return !!staged;
    },
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeIsMultilinguale',
      'focusContent',
      'focusContentId',
      'focusContentBacklinks',
      'collectionsViewerIsLoading',
      'importArticleIsLoading',
      'editingLanguage',
    ]),
    ...mapGetters('adminModule', ['reviewers']),
    ...mapGetters(['companyAllowAutomaticTranslationPreference']),
  },
  methods: {
    setTranslationStatusesToDisplay() {
      this.translationStatusesToDisplay = Object.keys(
        this.translationStatuses,
      ).filter(
        (lang) =>
          !this.translationStatuses[lang].hideToast &&
          this.translationStatuses[lang].type !== 'file-import',
      );

      this.brainFileImportStatusesToDisplay = Object.keys(
        this.translationStatuses,
      ).filter(
        (lang) =>
          !this.translationStatuses[lang].hideToast &&
          this.translationStatuses[lang].type === 'file-import',
      );
    },
    async goToSimilarContent() {
      const path = this.$route.path.includes('web-parametric')
        ? '/web-parametric/content/'
        : '/knowledge/content/';

      this.$router.push({
        path: path + this.similarContentId,
      });
      this.similarContentId = '';
    },
    async handleCreateAutomation(automation) {
      const newAutomation = await this.createAutomation(automation);

      if (this.rootId !== this.stepId) {
        const stepAutomations = this.step.automations
          ? [...this.step.automations]
          : [];
        stepAutomations.push(newAutomation);
        this.step.automations = stepAutomations;
      }

      this.newAutomationId = newAutomation.id;
      this.$message({
        message: this.$t('automations.confirmations.create'),
        type: 'success',
      });
    },
    async handleUpdateAutomation(automation) {
      const newAutomation = await this.updateAutomation(automation);

      if (this.rootId !== this.stepId) {
        const stepAutomations = [...this.step.automations];
        const index = stepAutomations.findIndex(
          (automationItem) => automationItem.id === automation.id,
        );

        if (index === -1) return;

        stepAutomations.splice(index, 1, newAutomation);

        this.step.automations = stepAutomations;
      }

      this.$message({
        message: this.$t('automations.confirmations.update'),
      });
    },
    async handleDeleteAutomation(id) {
      await this.deleteAutomation(id);

      if (this.rootId !== this.stepId) {
        const stepAutomations = [...this.step.automations];
        this.step.automations = stepAutomations.filter(
          (automation) => automation.id !== id,
        );
      }

      this.$message({
        message: this.$t('automations.confirmations.delete'),
        type: 'error',
      });
    },
    forceStepValue() {
      if (!this.$route.params.stepId) this.step = { ...this.focusContent };
    },
    // UPDATE METHODS
    async handleUpdateContent({ id, payload, source, displayLoader }) {
      try {
        this.localLoading = displayLoader;
        let newContent;
        if (payload.body && payload.body === this.root.body) return;
        // The mutation in DB is done through setContentRedirection in PopoverRedirection component
        // Here we only want to update the local state
        if (payload.redirection) {
          const { entityId, entityType, lang } = payload.redirection;

          const redirectedStepIdx = this.step.children.findIndex(
            (child) => child.id === id,
          );

          if (redirectedStepIdx === -1) return;

          newContent = {
            ...this.step.children[redirectedStepIdx],
            redirection: entityId
              ? {
                  isDeleted: false,
                  entityId,
                  entityType,
                  lang,
                }
              : undefined,
          };
        } else {
          const { body, label } = payload;
          if (
            (body || label) &&
            this.focusKnowledgeIsMultilinguale &&
            this.availableLanguagesForUpdateTranslations &&
            this.availableLanguagesForUpdateTranslations.length
          ) {
            this.displayUpdateTranslations = true;
          }
          const domParser = new DOMParser().parseFromString(body, 'text/html');

          const inlineMentions = domParser.querySelectorAll('.inline-mention');
          const resolveMentions = Array.from(inlineMentions).map((el) => {
            return {
              targetId: el.attributes['data-node-id'].value,
              targetType: el.attributes['data-node-type'].value,
              blockId: el.parentNode.attributes['data-uid'].value,
            };
          });

          const uniqueMentions = [...new Set(resolveMentions)];

          newContent = await this.updateContent({
            id,
            payload: { ...payload, mentions: uniqueMentions },
          });
        }
        this.localUpdateContent(newContent, source);
      } finally {
        this.localLoading = false;
      }
    },
    async handleUpdateStagedContentLabel({ payload }) {
      try {
        this.localLoading = true;
        const { label } = payload;
        await this.updateStagedContent({
          id: this.root.id,
          lang: this.root.returnLanguage,
          label,
          body: this.root.stagedData.body,
        });
      } finally {
        this.localLoading = false;
      }
    },
    async handleToggleKeyStep({ id, type }) {
      await this.toggleKeyStep({ id, type });
      this.step.type = type;
    },
    async updateSettings({ id, payload }) {
      await this.updateContentSettingsAction({
        id,
        payload,
      });
    },
    async archiveContent({ id }) {
      const success =
        await this.$services.parametricContents.bulkArchiveContents([id]);
      if (success) this.$router.go(-1);
    },
    async deleteChild(parentId, id) {
      const success =
        await this.$services.parametricContents.bulkArchiveContents([id]);
      if (success) {
        this.localUpdateContent({
          id: parentId,
          path: this.step.path.filter((el) => el !== id),
          children: this.step.children.reduce((acc, el) => {
            if (el.redirection && el.redirection.entityId === id)
              el.redirection.isDeleted = true;
            if (el.id !== id) acc.push(el);
            return acc;
          }, []),
        });
      }
    },
    async updateChildrenOrder({ id, payload, source }) {
      const newChildren = payload.path.map((id) =>
        this.step.children.find((c) => c.id === id),
      );
      this.localUpdateContent({
        id,
        path: payload.path,
        children: newChildren,
      });
      await this.handleUpdateContent({ id, payload, source });
      this.$root.$emit('UPDATE_IS_LOADING', false);
    },
    async addChild(payload) {
      const { id, type, label } = payload;

      const lang = this.editingLanguage;
      const newChild = await this.$services.parametricContents.addChild(
        id,
        type,
        label.trim(),
        lang,
      );
      newChild;
      this.step = Object.assign({}, this.step, {
        path: this.step.path.concat([newChild.id]),
        children: this.step.children.concat([newChild]),
      });
      if (this.stepId === this.rootId) this.root = { ...this.step };
    },
    async relocateChildLocal({
      childId,
      formerParentId,
      newParentId,
      onlyLocalUpdate = false,
    }) {
      if (!onlyLocalUpdate) {
        await this.relocateChild({
          childId,
          formerParentId,
          newParentId,
        });
      }
      this.localUpdateContent({
        id: this.step.id,
        path: this.step.path.filter((id) => id !== childId),
        children: this.step.children.filter((child) => child.id !== childId),
      });
    },
    async setContentTrustMiddleware(event) {
      try {
        this.localLoading = true;
        await this.bulkUpdateStatus({
          contentIds: [this.rootId],
          key: 'isOutdated',
          value: !event.trust,
        });
        const message = `knowledge.verification-policy.${
          event.trust ? 'trust-message' : 'untrust-message'
        }`;
        this.$message({
          message: this.$t(message),
          type: 'success',
        });
      } finally {
        this.localLoading = false;
      }
    },
    async handleUpsertVerificationPolicy(event) {
      await this.upsertVerificationPolicy(event);
      return this.$message({
        message: this.$t('knowledge.verification-policy.update-message'),
        type: 'success',
      });
    },
    async handleDeleteVerificationPolicy(event) {
      await this.deleteVerificationPolicy(event);
      return this.$message({
        message: this.$t('knowledge.verification-policy.delete-message'),
        type: 'success',
      });
    },
    async handleUpsertPublicationPolicy(event) {
      await this.upsertPublicationPolicy(event);
      return this.$message({
        message: this.$t('knowledge.publication-policy.update-message'),
        type: 'success',
      });
    },
    async handleDeletePublicationPolicy(event) {
      await this.deletePublicationPolicy(event);
      return this.$message({
        message: this.$t('knowledge.publication-policy.delete-message'),
        type: 'success',
      });
    },
    async handleUpdateUnpublishDate(event) {
      await this.updateUnpublishDate(event);
    },
    // DEBOUNCE CONTENT UPDATE
    delayedUpdateContent({ id, payload }) {
      if (this.isStagedContent)
        return this.debounceUpdateStagedContent({
          id,
          label: this.root.stagedData.label,
          ...payload,
        });

      this.debounceUpdateContent({
        id,
        payload,
        source: 'focus',
      });
    },

    handlePublishStagedVersion() {
      this.localLoading = true;
      setTimeout(() => {
        this.publishStagedVersion({
          id: this.root.id,
          lang: this.root.returnLanguage,
        });
        this.localLoading = false;
      }, 2000);
    },

    // UTILS
    focusNode(node) {
      this.step = Object.assign({}, this.step, node);
      if (this.step && ['Step', 'keyStep'].includes(this.step.type)) {
        this.setFocusStep(this.step);
        this.$refs.parametricContentBuilder.scrollTop = 0;
      }
    },
    localUpdateContent(newContent, source = 'focus') {
      const { id } = newContent;
      if (source === 'root') {
        this.root = Object.assign({}, this.root, newContent);
        if (id === this.stepId) this.step = { ...this.root };
      } else if (source === 'focus') {
        if (id === this.stepId) {
          this.step = Object.assign({}, this.step, newContent);
          if (id === this.rootId) this.root = { ...this.step };
        }
      } else if (source === 'child') {
        const childIdx = this.step.children.findIndex(
          (c) => c.id === newContent.id,
        );
        if (childIdx !== -1) {
          const children = this.step.children;
          children[childIdx] = Object.assign(
            {},
            children[childIdx],
            newContent,
          );
          this.step = {
            ...this.step,
            children,
          };
        }
      }
    },
    async displayMoreOptions() {
      this.product.resolutions =
        await this.$services.products.getContentsByProductId(this.product.id);
    },
    openFeedbackModal(content) {
      this.$emit('open-feedback-modal', content);
    },
    hideFeedbackModal() {
      this.$emit('close-feedback-modal');
    },
    async addNotification(notification) {
      try {
        await this.addAgentNotification({
          notification: {
            ...notification,
            companyId: this.$store.state.companyId,
          },
        });
        this.$message({
          message: `La notification a bien été ajoutée`,
          type: 'success',
        });
      } catch (e) {
        this.$message({
          message: `Une erreur s'est produite`,
          type: 'error',
        });
      }
    },
    async openSetLanguageModal(lang) {
      this.setLanguageModal = true;
      this.language = lang || this.editingLanguage;
    },
    async openUpdateTranslationsModal() {
      this.updateTranslationsModal = true;
    },
    async setNewLanguage({ label, enableAutoTranslate, sourceLanguage }) {
      this.storeVersionEvent({
        eventName: 'trigger',
        event: {
          knowledgeId: this.focusContent.knowledgeId,
          contentId: this.focusContent.id,
          contentType: this.focusContent.type,
          sourceLanguage: sourceLanguage || this.editingLanguage,
          targetLanguage: this.language,
          method: enableAutoTranslate ? 'automatic' : 'manual',
        },
      });
      if (enableAutoTranslate) {
        this.handleTriggerContentTranslation({
          targetLanguage: this.language,
          sourceLanguage: sourceLanguage || this.editingLanguage,
          triggerSource: 'create',
        });
      }
      if (label) {
        this.updateContent({
          id: this.root.id,
          payload: {
            label: label,
            body: this.root.body,
            lang: this.language,
            isNewLanguage: true,
          },
          source: 'root',
        });
      }
      this.language = '';
      this.setLanguageModal = false;
    },
    resetLanguageToast(targetLanguage) {
      if (!targetLanguage) this.translationStatuses = {};
      else if (this.translationStatuses[targetLanguage]) {
        this.translationStatuses[targetLanguage].hideToast = true;
      }
      this.setTranslationStatusesToDisplay();
    },
    handleQuitTranslationToast({ targetLanguage }) {
      this.forceBlur = false;
      this.translationStatuses[targetLanguage].hideToast = true;
      this.setTranslationStatusesToDisplay();
    },

    async handleRetryTranslation({
      sourceLanguage,
      targetLanguage,
      triggerSource,
    }) {
      this.handleTriggerContentTranslation({
        targetLanguage,
        sourceLanguage,
        triggerSource,
      });
    },
    async handleUpdateTranslations({ targetLanguages }) {
      await Promise.all(
        targetLanguages.map((targetLanguage) =>
          this.handleTriggerContentTranslation({
            targetLanguage,
            sourceLanguage: this.editingLanguage,
            triggerSource: 'update',
          }),
        ),
      );
      this.updateTranslationsModal = false;
      this.displayUpdateTranslations = false;
      this.storeVersionEvent({
        eventName: 'updateTrigger',
        event: {
          knowledgeId: this.focusContent.knowledgeId,
          contentId: this.focusContent.id,
          contentType: this.focusContent.type,
          sourceLanguage: this.editingLanguage,
          targetLanguages,
          targetLanguagesNumber: targetLanguages.length,
        },
      });
    },
    async handleTriggerContentTranslation({
      sourceLanguage,
      targetLanguage,
      triggerSource,
    }) {
      const lockId = await this.triggerContentTranslation({
        documentId: this.focusContentId,
        sourceLanguage: sourceLanguage || this.editingLanguage,
        targetLanguage: targetLanguage,
      });
      this.translationStatuses[targetLanguage] = {
        lockId,
        status: 'loading',
        targetLanguage: targetLanguage,
        triggerSource,
        type: lockId.split(':')[0],
      };
      this.loadingLanguages.push(targetLanguage);
      if (targetLanguage === this.editingLanguage) this.forceBlur = true;
      this.updateTranslationStatuses(targetLanguage);
    },
    openUnsetLanguageModal(lang) {
      this.languageToUnset = lang;
      this.openModal({
        component: 'DeleteContentModal',
        uid: this.unsetLanguageModalUniqueKey,
        props: { display: true, lang, deleteType: 'language' },
      });
    },
    unsetLanguage(modalResp) {
      if (!modalResp) return;
      this.unsetContentLanguage({
        contentId: this.root.id,
        lang: this.languageToUnset,
      });
    },
    storeEvent() {
      if (this.$route.path.includes('web-parametric')) {
        this.$services.events.content.read(
          this.step,
          window.location.href,
          'WEB_PARAMETRIC',
        );
      }
    },
    handleOnScroll() {
      const editorDragHandleElement = document.getElementsByClassName(
        'mayday-editor__drag-icon-container',
      );

      if (!editorDragHandleElement || !editorDragHandleElement[0]) return;

      editorDragHandleElement[0].style.visibility = 'hidden';
    },
    async parseContributors(content) {
      if (!this.reviewers || !this.reviewers.users)
        await this.getCompanyReviewers();
      const data = await this.fetchContentHistory({
        id: content.id,
        limit: 3,
        page: 1,
        lang: content.returnLanguage,
      });
      const results = data.results.sort((a, b) => b.updatedAt - a.updatedAt);
      if (
        data.total < 3 &&
        !results.find((contributor) =>
          content.enrichedAuthor
            ? contributor.authorId === content.enrichedAuthor.id
            : contributor.authorId === content.author,
        )
      ) {
        if (!content.author && !content.enrichedAuthor.id) {
          results.push({
            authorId: '0',
            username: this.$t('knowledge.contributors.deleted-user'),
            updatedAt: content.createdAt,
          });
        } else
          results.push({
            authorId: content.author || content.enrichedAuthor.id,
            username: content.enrichedAuthor
              ? content.enrichedAuthor.username
              : this.$t('knowledge.contributors.deleted-user'),
            updatedAt: content.createdAt,
          });
        data.total++;
      }
      return { total: data.total, contributors: results };
    },
    onWindowClose() {
      this.leaveContentGroup({
        contentId: this.rootId,
        lang: this.editingLanguage || 'default',
      });
    },
    onTabChange() {
      this.idleContentGroup({
        contentId: this.rootId,
        lang: this.editingLanguage || 'default',
        isIdle: document.visibilityState === 'hidden',
      });
    },
    onBrowserChange(event) {
      this.idleContentGroup({
        contentId: this.rootId,
        lang: this.editingLanguage || 'default',
        isIdle: event.type === 'blur',
      });
    },
    async updateTranslationStatuses(targetLanguage) {
      if (this.translationStatuses[targetLanguage].lockId === 'skip') {
        this.setTranslationStatusesToDisplay();
        return;
      }

      if (
        this.translationStatuses[targetLanguage].status === 'pending' ||
        this.translationStatuses[targetLanguage].status === 'loading'
      ) {
        const translationStatus = this.translationStatuses[targetLanguage];
        if (
          !this.loadingLanguages.includes(targetLanguage) &&
          translationStatus.type !== 'file-import'
        )
          this.loadingLanguages.push(targetLanguage);

        setTimeout(async () => {
          if (
            !this.translationStatuses[targetLanguage] ||
            !this.translationStatuses[targetLanguage].lockId ||
            !this.translationStatuses[targetLanguage].status ||
            this.translationStatuses[targetLanguage].lockId === 'skip'
          )
            return;

          const taskStatus = await await this.getBrainTaskStatus({
            lockId: this.translationStatuses[targetLanguage].lockId,
            targetLanguage,
          });
          this.translationStatuses[targetLanguage].status = taskStatus.status;
          this.updateTranslationStatuses(targetLanguage);
        }, 5000);
      }

      if (this.translationStatuses[targetLanguage].status === 'done') {
        this.storeVersionEvent({
          eventName: 'done',
          event: {
            knowledgeId: this.focusContent.knowledgeId,
            contentId: this.focusContent.id,
            contentType: this.focusContent.type,
            sourceLanguage: this.editingLanguage,
            targetLanguage,
            triggerSource:
              this.translationStatuses[targetLanguage].triggerSource,
            method: 'automatic',
          },
        });
        if (this.translationStatuses[targetLanguage].hideToast)
          this.translationStatuses[targetLanguage].hideToast = false;
        this.loadingLanguages = this.loadingLanguages.filter(
          (val) => val !== targetLanguage,
        );
      }

      this.setTranslationStatusesToDisplay();
    },
    async handleHideUpdateTranslationsBanner() {
      this.hideUpdateTranslationsBanner = true;
      this.storeVersionEvent({
        eventName: 'updateHideBanner',
        event: {
          knowledgeId: this.focusContent.knowledgeId,
          contentId: this.focusContent.id,
          contentType: this.focusContent.type,
          availableSourceLanguages:
            this.availableLanguagesForUpdateTranslations,
        },
      });
    },
    async handleBrainImport(args) {
      const editingLanguage = this.editingLanguage || 'fr-fr';

      this.translationStatuses[editingLanguage] = {
        lockId: 'skip',
        status: 'loading',
        type: 'file-import',
        targetLanguage: editingLanguage,
        triggerSource: editingLanguage,
        payload: args,
      };
      this.loadingLanguages.push(editingLanguage);
      this.forceBlur = true;
      this.updateTranslationStatuses(editingLanguage);

      try {
        const lockId = await this.triggerBrainImportFile({
          rawFiles: args.rawFiles,
          contentId: this.root.id,
          contentType: this.root.type,
          lang: editingLanguage,
          importType: args.importType,
          settings: args.settings,
        });

        this.translationStatuses[editingLanguage].lockId = lockId;
      } catch (err) {
        this.translationStatuses[editingLanguage].status = 'failed';
      }

      this.updateTranslationStatuses(editingLanguage);
    },
    async handleBrainFilesMerge(args) {
      this.forceBlur = true;
      const editingLanguage = this.editingLanguage;

      this.brainTasks['files-merge'] = {
        status: 'loading',
        payload: args,
      };

      try {
        const lockId = await this.triggerBrainTask({
          taskType: 'files-merge',
          payload: {
            rawFiles: args.rawFiles,
            contentId: this.root.id,
            lang: editingLanguage,
            settings: args.settings,
          },
        });

        await this.initBrainTaskStatusPoller(lockId);
      } catch (err) {
        console.log(err);
        this.brainTasks['files-merge'].status = 'failed';
      }
    },
    async handleBrainGenerateFromPrompt(args) {
      this.forceBlur = true;
      const editingLanguage = this.editingLanguage;

      this.brainTasks['generation'] = {
        status: 'loading',
        payload: args,
      };

      try {
        const lockId = await this.triggerBrainTask({
          taskType: 'generation',
          payload: {
            prompt: args.prompt,
            documentId: this.root.id,
            lang: editingLanguage,
          },
        });

        await this.initBrainTaskStatusPoller(lockId);
      } catch (err) {
        console.log(err);
        this.brainTasks['generation'].status = 'failed';
      }
    },
    async initBrainTaskStatusPoller(lockId) {
      this.forceBlur = true;
      const taskType = lockId.split(':')[0];

      if (!this.brainTasks[taskType])
        this.brainTasks[taskType] = {
          status: 'loading',
        };

      try {
        await this.pollBrainTaskStatus({
          lockId,
          status: 'loading',
          // eslint-disable-next-line no-unused-vars
          onStatusChangeCallback: (newVal, oldVal) => {
            const { status } = newVal;
            this.brainTasks = Object.assign({}, this.brainTasks, {
              [taskType]: {
                ...this.brainTasks[taskType],
                status,
              },
            });
          },
        });
      } catch (err) {
        console.log(err);
        this.brainTasks[taskType].status = 'failed';
      }
    },
    resetBrainTasks() {
      this.forceBlur = false;
      this.brainTasks = {};
    },
    handleBrainTaskInterruption(taskType) {
      this.forceBlur = false;
      this.brainTasks = Object.assign({}, this.brainTask, {
        [taskType]: undefined,
      });
    },
    async reloadPage() {
      this.resetBrainTasks();
      this.handleQuitTranslationToast({ targetLanguage: this.editingLanguage });
      await this.getFocusContentDetails(true);
    },
    ...mapActions('kbStore', ['pushContentToCasePath']),
    ...mapActions('knowledgeModule', [
      'updateContent',
      'toggleKeyStep',
      'updateContentSettingsAction',
      'bulkUpdateStatus',
      'createAutomation',
      'updateAutomation',
      'deleteAutomation',
      'upsertVerificationPolicy',
      'deleteVerificationPolicy',
      'upsertPublicationPolicy',
      'deletePublicationPolicy',
      'updateUnpublishDate',
      'unsetContentLanguage',
      'relocateChild',
      'fetchContentHistory',
      'setFocusStep',
      'triggerContentTranslation',
      'getBrainTaskStatus',
      'getLoadingLanguages',
      'storeVersionEvent',
      'triggerBrainImportFile',
      'triggerBrainTask',
      'pollBrainTaskStatus',
      'getFocusContentDetails',
      'updateStagedContent',
      'createStagedVersion',
      'publishStagedVersion',
    ]),
    ...mapActions('notificationModule', ['addAgentNotification']),
    ...mapActions('modalsModule', ['openModal']),
    ...mapActions('adminModule', [
      'getCompanyReviewers',
      'getContributors',
      'addWPSGroupPermission',
    ]),
    ...mapActions('websocketModule', [
      'joinContentGroup',
      'leaveContentGroup',
      'idleContentGroup',
    ]),
  },
  watch: {
    ['$route.path']: {
      handler: async function () {
        this.resetBrainTasks();
        this.resetLanguageToast();
        await this.getContributors();
        this.displayUpdateTranslations = false;
      },
      immediate: true,
    },
    ['$route.params']: {
      handler: async function (to, from) {
        this.resetBrainTasks();
        this.resetLanguageToast();
        this.displayUpdateTranslations = false;
        if (from && from.contentId)
          this.leaveContentGroup({
            contentId: from.contentId,
            lang: from.lang || 'default',
          });

        if (to && to.contentId) {
          const granted = await this.addWPSGroupPermission({
            contentId: to.contentId,
            lang: to.lang || 'default',
          });
          if (granted)
            this.joinContentGroup({
              contentId: to.contentId,
              lang: to.lang || 'default',
            });
        }
      },
      immediate: true,
    },

    async $route(to, from) {
      this.resetBrainTasks();
      this.resetLanguageToast();
      this.displayUpdateTranslations = false;
      if (!to.params.stepId) {
        this.step = Object.assign({}, this.root);
      }
      if (
        `${to.params.contentId}/${to.params.stepId}` !==
        `${from.params.contentId}/${from.params.stepId}`
      ) {
        this.storeEvent();
      }
      this.similarContentId = '';

      if (to.params.lang !== from.params.lang) {
        this.debounceUpdateContent.flush();
      }
    },
    async focusContent(newVal, oldVal) {
      if ((newVal && !oldVal) || newVal.id !== oldVal.id) {
        this.resetBrainTasks();
        this.resetLanguageToast();
        this.displayUpdateTranslations = false;
        await this.pushContentToCasePath(newVal);
        const { total, contributors } = await this.parseContributors(newVal);
        this.total = total;
        this.contributors = contributors;
        if (!newVal.enrichedAuthor.id)
          newVal.enrichedAuthor.username = this.$t(
            'knowledge.contributors.deleted-user',
          );
      } else if (
        newVal &&
        oldVal &&
        parseInt(newVal.updatedAt) > parseInt(oldVal.updatedAt)
      ) {
        await this.pushContentToCasePath(newVal);
        const { total, contributors } = await this.parseContributors(newVal);
        this.total = total;
        this.contributors = contributors;
        if (!newVal.enrichedAuthor.id)
          newVal.enrichedAuthor.username = this.$t(
            'knowledge.contributors.deleted-user',
          );
      }
      if (newVal && newVal.lockIds && Object.keys(newVal.lockIds).length) {
        Object.keys(newVal.lockIds).forEach((targetLanguage) => {
          if (
            this.translationStatuses[targetLanguage] &&
            this.translationStatuses[targetLanguage].status === 'failed'
          )
            return;

          let lockId = newVal.lockIds[targetLanguage];
          const taskType = lockId.split(':')[0];

          this.resetBrainTasks();
          if (['files-merge'].includes(taskType)) {
            this.initBrainTaskStatusPoller(lockId);
          } else {
            this.resetLanguageToast(targetLanguage);
            this.translationStatuses[targetLanguage] = {
              lockId,
              status: 'loading',
              targetLanguage,
              type: taskType,
            };

            this.updateTranslationStatuses(targetLanguage);
          }
        });
      } else this.loadingLanguages = [];
    },
    stepId: {
      handler: function () {
        this.debounceUpdateContent.flush();
      },
      immediate: true,
    },
    root() {
      this.diagBuilderKey += 1;
    },
    async displayUpdateTranslations(newVal) {
      this.hideUpdateTranslationsBanner = !newVal;
      if (newVal)
        this.storeVersionEvent({
          eventName: 'updateAvailable',
          event: {
            knowledgeId: this.focusContent.knowledgeId,
            contentId: this.focusContent.id,
            contentType: this.focusContent.type,
            availableSourceLanguages:
              this.availableLanguagesForUpdateTranslations,
          },
        });
    },
  },
  async mounted() {
    const container = this.$refs['parametricContentBuilder'];
    await this.getCompanyReviewers();
    if (this.root) {
      const { total, contributors } = await this.parseContributors(this.root);
      this.total = total;
      this.contributors = contributors;
      if (!this.root.enrichedAuthor.id)
        this.root.enrichedAuthor.username = this.$t(
          'knowledge.contributors.deleted-user',
        );
    }
    container.addEventListener('scroll', this.handleOnScroll);
    window.addEventListener('beforeunload', this.onWindowClose);
    window.addEventListener('blur', this.onBrowserChange);
    window.addEventListener('focus', this.onBrowserChange);
    document.addEventListener('visibilitychange', this.onTabChange);
    if (
      this.focusContent &&
      this.focusContent.lockIds &&
      Object.keys(this.focusContent.lockIds).length
    ) {
      Object.keys(this.focusContent.lockIds).forEach((targetLanguage) => {
        let lockId = this.focusContent.lockIds[targetLanguage];
        const taskType = lockId.split(':')[0];

        this.resetBrainTasks();
        if (['files-merge'].includes(taskType)) {
          this.initBrainTaskStatusPoller(lockId);
        } else {
          this.resetLanguageToast(targetLanguage);
          this.translationStatuses[targetLanguage] = {
            lockId: this.focusContent.lockIds[targetLanguage],
            status: 'loading',
            targetLanguage,
            type: this.focusContent.lockIds[targetLanguage].split(':')[0],
          };
          this.updateTranslationStatuses(targetLanguage);
        }
      });
    } else this.loadingLanguages = [];
  },
  beforeDestroy() {
    this.debounceUpdateContent.flush();
    const container = this.$refs['parametricContentBuilder'];
    container.removeEventListener('scroll', this.handleOnScroll);
    this.leaveContentGroup({
      contentId: this.rootId,
      lang: this.editingLanguage || 'default',
    });
    window.removeEventListener('beforeunload', this.onWindowClose);
    window.removeEventListener('blur', this.onBrowserChange);
    window.removeEventListener('focus', this.onBrowserChange);
    document.removeEventListener('visibilitychange', this.onTabChange);
  },
};
</script>

<style lang="scss" scoped>
.hosting-content-row {
  height: calc(100vh - 60px);
  overflow: auto;
  position: relative;
  margin-right: 0px;
}

.untranslate-content {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
}

.content-blur {
  width: 100%;
  height: 100%;
  background: rgba(246, 249, 252, 0.5);
}

.toast-position {
  right: 12px;
}
.toast-position-2 {
  top: 200px;
  right: 12px;
}
.toast-position-3 {
  top: 400px;
  right: 12px;
}

.collapse-enter-active {
  animation: collapse-enter 0.4s ease-in-out;
}

.collapse-leave-active {
  animation: collapse-enter 0.4s ease-in-out reverse;
}

@keyframes collapse-enter {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0);
  }
}
.translation-banner {
  position: absolute;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
