<template>
  <el-dropdown
    size="small"
    trigger="click"
    :class="['publication-btn', buttonProps.class]"
  >
    <el-tooltip placement="top" effect="dark" :disabled="disableTootlip">
      <template #content>
        <!-- Date of publication -->
        <p class="text-small mb-0">
          {{ buttonProps['tooltip-date'] }}
          <strong> {{ formattedPublicationDate }} </strong> .
        </p>
      </template>

      <div class="d-flex align-items-center">
        <span>{{ buttonProps.label }}</span>
        <i class="el-icon-arrow-down el-icon--right ml-2"></i>
      </div>
    </el-tooltip>

    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        @click.native="$emit('publish')"
        class="d-flex align-items-center"
      >
        <div class="item-icon-wrapper">
          <font-awesome-icon
            class="mr-2 item-icon text-grey"
            :icon="['fad', this.published ? 'eye-slash' : 'eye']"
          />
        </div>
        {{
          published
            ? $t('knowledge.actions.draft')
            : $t('knowledge.actions.publish')
        }}</el-dropdown-item
      >
      <el-dropdown-item
        v-if="!published"
        @click.native="$emit('update-display-modal', true)"
        class="d-flex align-items-center"
      >
        <div class="item-icon-wrapper">
          <font-awesome-icon
            class="mr-2 item-icon text-grey"
            :icon="['fad', 'calendar']"
          />
        </div>
        {{ $t('knowledge.actions.schedule-publication') }}
      </el-dropdown-item>
      <el-dropdown-item
        v-if="!hasStagedVersion"
        @click.native="$emit('create-staged-version', true)"
        class="d-flex align-items-center"
      >
        <div class="item-icon-wrapper">
          <font-awesome-icon
            class="mr-2 item-icon text-grey"
            :icon="['fad', 'file']"
          />
        </div>
        {{ $t('knowledge.staged-version.create') }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'publication-policy-button',
  props: {
    publicationDate: {
      type: String,
      required: false,
    },
    published: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    hasStagedVersion: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disableTootlip() {
      return (
        this.disabled || !(!!this.formattedPublicationDate && !this.published)
      );
    },
    buttonProps() {
      return this.published
        ? {
            label: this.$t('knowledge.published').toUpperCase(),
            class: 'published',
            tooltip: this.$t(
              'knowledge.publication-policy.button-tooltip-published',
            ),
          }
        : {
            label: this.$t('knowledge.draft').toUpperCase(),
            class: 'draft',
            tooltip: this.$t(
              `knowledge.publication-policy.button-tooltip-draft${
                this.formattedPublicationDate ? '' : '-no-policy'
              }`,
            ),
            'tooltip-date': this.$t(
              'knowledge.publication-policy.button-tooltip-date',
            ),
            'icon-tooltip': this.$t(
              'knowledge.publication-policy.icon-tooltip-draft',
            ),
          };
    },
    formattedPublicationDate() {
      if (!this.publicationDate) return '';

      return new Date(parseInt(this.publicationDate)).toLocaleDateString(
        'fr-FR',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.draft {
  color: $grey-5-mayday;
  background-color: $grey-2-mayday;
  border-radius: 2px;
  border-color: 1px solid $grey-4-mayday;
}
.published {
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 2px;
  border: 1px solid $blue-mayday;
}

.publication-btn {
  border: 1px solid;
  font-size: 10px;
  font-weight: 700;
  padding: 2px 4px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.text-small {
  font-size: 1.1em;
}

.item-icon-wrapper {
  width: 12px !important;
  height: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
</style>
