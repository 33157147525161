var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"builder-side-bar"},[_c('div',{staticClass:"sticky-top"},[(_vm.displayAiDrawerOptions && !_vm.isStagedContent)?_c('AiDrawerOption',{attrs:{"type":_vm.type,"generating-summary":_vm.generatingSummary,"generating-action-items":_vm.generatingActionItems,"generating-guide":_vm.generatingGuide,"summary":_vm.content.summary,"short-summary":_vm.content.shortSummary,"action-items":_vm.content.actionItems,"ai-drawer-tab":_vm.aiDrawerTab,"body-length":_vm.content.body.length,"minimum-content-size":_vm.minimumContentSize},on:{"generate-content":function($event){return _vm.$emit('generate-content', $event)},"generate-guide":function($event){return _vm.$emit('generate-guide')},"generate-open-prompt":function($event){return _vm.$emit('generate-open-prompt')}}}):_vm._e(),(
        _vm.rootId &&
        _vm.displayOptions.logigram &&
        _vm.editable &&
        !_vm.isTemplate &&
        !_vm.isStagedContent
      )?_c('LogigramOption',{attrs:{"diagnostic-id":_vm.rootId,"type":_vm.type,"user-permissions":_vm.userPermissions},on:{"relocate-child":function($event){return _vm.$emit('relocate-child', $event)}}}):_vm._e(),(_vm.displayOptions.import && _vm.editable && !_vm.isStagedContent)?_c('ImportedArticlesOption',{attrs:{"is-read-only":_vm.content.isReadOnly,"content-id":_vm.content.id,"type":_vm.type}}):_vm._e(),(_vm.displayOptions.keyStep && _vm.editable && !_vm.isStagedContent)?_c('KeyStepOption',{attrs:{"type":_vm.type,"is-key-step":_vm.isKeyStep},on:{"click":function($event){return _vm.$emit('toggle-key-step', $event)}}}):_vm._e(),(_vm.displayOptions.labels && _vm.editable && !_vm.isStagedContent)?_c('SearchOption',{attrs:{"tags":_vm.content.tags,"type":_vm.type,"user-permissions":_vm.userPermissions,"is-hidden":_vm.isHidden},on:{"update-content":function($event){return _vm.$emit('update-content', $event)}}}):_vm._e(),(_vm.displayOptions.trigger && _vm.editable && !_vm.isStagedContent)?_c('TriggerOption',{attrs:{"type":_vm.type,"active":_vm.triggerActive},on:{"click":function($event){return _vm.$emit('click:trigger', $event)}}}):_vm._e(),(_vm.showHistorization && !_vm.isStagedContent)?_c('HistorizationOption',{attrs:{"type":_vm.type},on:{"click":function($event){return _vm.$emit('click:historization', _vm.payload)}}}):_vm._e(),(_vm.displayOptions.notifications && _vm.editable && !_vm.isStagedContent)?_c('NotificationsOption',{attrs:{"node-id":_vm.nodeId,"node-label":_vm.content.label,"node-lang":_vm.content.returnLanguage,"type":_vm.content.type,"source":"CONTENT"},on:{"add-notification":function($event){return _vm.$emit('add-notification', $event)}}}):_vm._e(),(
        _vm.displayOptions.automations &&
        (_vm.hasIntercom || _vm.hasZendesk || _vm.hasSalesforce) &&
        _vm.editable &&
        !_vm.isStagedContent
      )?_c('AutomationsOption',{attrs:{"type":_vm.type,"knowledge-id":_vm.knowledgeId,"node-id":_vm.nodeId,"automations":_vm.automations,"new-automation-id":_vm.newAutomationId,"user-permissions":_vm.userPermissions},on:{"create-automation":function($event){return _vm.$emit('create-automation', $event)},"update-automation":function($event){return _vm.$emit('update-automation', $event)},"delete-automation":function($event){return _vm.$emit('delete-automation', $event)}}}):_vm._e(),(_vm.hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED'))?_c('WorkflowActionOption',{attrs:{"type":_vm.type,"active":!!_vm.workflowActive}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }